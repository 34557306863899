import React, { useEffect, useState } from "react";
import Img from "gatsby-image";
import { StaticQuery, Link, graphql } from "gatsby";
import { Container, Col, Row } from "react-bootstrap";
import styled from "styled-components";
import goArrow from "../assets/icon-arrow.svg";
import goArrowWhite from "../assets/icon-arrow-white.svg";
import Seo from "../components/SEO";
import Started from "../components/Layout/started";
import Layout from "../components/layout";
import PageHeader from "../components/pageHeader";
import * as pageStyles from "./page.module.less";
import * as styles from "./case-studies.module.less";
import LocalPagination from "../components/pagination";
import { scroller } from "react-scroll";

const CASE_STUDIES_POST_QUERY = graphql`
  query CaseStudiesPostQuery {
    blogDefault: file(
      relativePath: { eq: "../assets/Blog/blog_default_fullhd.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1024) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    allMarkdownRemark(
      filter: { fields: { sourceInstanceName: { eq: "case-study" } } }
      sort: { order: DESC, fields: frontmatter___date }
    ) {
      edges {
        node {
          frontmatter {
            title
            slug
            sample
            tags
            date(formatString: "MMMM DD, YYYY")
            featuredImage {
              childImageSharp {
                fluid {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
          html
          excerpt(pruneLength: 220)
        }
      }
    }
  }
`;

const GoArrow = styled.div`
  width: 100%;
  text-align: left;
  margin-top: ${({ noMargin }) => (noMargin ? "0" : "20px")};
  img {
    width: ${({ noMargin }) => (noMargin ? "13px" : "20px")};
    transition: all 0.5s;
  }
`;

const GoText = styled.span`
  font-size: 18px;
  font-weight: 600;
  letter-spacing: 0.03px;
  color: #1e5bb5;
  margin-right: 8px;
`;

const TopBlogDiv = styled.div`
  margin-top: -160px;
  background-color: white;
  position: relative;
  margin-bottom: 70px;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.2);
  transition: all 0.2s;
  border-radius: 8px;
  border-top-left-radius: 10px;
  &:hover {
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.25);
    ${GoArrow} {
      img {
        transform: translateX(2px);
        width: 20px;
      }
    }
  }
  @media screen and (max-width: 991px) {
    margin-top: -60px;
  }
  @media screen and (max-width: 768px) {
    margin-top: -80px;
    margin-bottom: 40px;
  }
`;

const StyledMainBlogPostHeader = styled.h1`
  font-size: 24px;
  font-weight: 600;
  letter-spacing: 0.03px;
  color: #242d41;
  margin-bottom: 30px;
  line-height: 1.33;
  @media screen and (max-width: 991px) {
    font-size: 20px;
    margin-bottom: 20px;
  }
`;

const StyledMainBlogText = styled.p`
  font-size: 18px;
  letter-spacing: 0.02px;
  color: rgba(36, 45, 65, 0.9);
  line-height: 1.56;
  font-weight: 300;
`;

const CaseStudiesHeading = styled.div`
  font-size: 20px;
  font-weight: 600;
  letter-spacing: 0.03px;
  color: #242d41;
`;

const LowerBlogText = styled.h4`
  color: #242d41;
  font-size: 28px;
  font-weight: 600;
  padding: 0 0 16px 0;
  line-height: 1.31;
`;

const BlogText = styled.div`
  font-size: 16px;
  color: rgba(36, 45, 65, 0.9);
  line-height: 1.56;
`;

const PostWrapper = styled.div`
  width: 100%;
`;

const TopTextContent = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const Border = styled.hr`
  background-color: #eeeff3;
`;

const StandardBlogPostContent = styled.div`
  position: relative;
  background: #fff;
  transition: all 0.2s;
  margin-bottom: 60px;
`;

const GradientSection = styled.div`
  height: 45px;
  width: 100%;
  background-image: linear-gradient(to top, #f0f5f9, #ffffff);
  @media screen and (max-width: 768px) {
    margin-top: ${({ noMargin }) => (noMargin ? "0" : "-65px")};
  }
`;

const EventCard = styled.div`
  border-radius: 16px;
  box-shadow: 0 6px 20px 0 rgba(36, 45, 65, 0.1);
  background-color: #fff;
  padding: 16px;
  margin-bottom: 20px;
`;

const EventDate = styled.div`
  font-size: 14px;
  font-weight: 600;
  line-height: 1.29;
  color: rgba(36, 45, 65, 0.7);
  margin-bottom: 4px;
`;

const EventTitle = styled.div`
  font-size: 20px;
  font-weight: 600;
  line-height: 1.2;
  color: #242d41;
  margin-bottom: 12px;
`;

const EventType = styled.div`
  font-size: 16px;
  line-height: 1.5;
  color: rgba(36, 45, 65, 0.9);
  margin-bottom: 12px;
`;

const EventButton = styled.button`
  height: 40px;
  padding: 0 24px;
  font-size: 13px;
  border-radius: 21px;
  border: solid 2px #1e5bb5;
  background-color: #1e5bb5;
  color: #fff;
  display: flex;
  align-items: center;
  span {
    flex-shrink: 0;
    margin-right: 8px;
  }
`;

const DateWrapper = styled.div`
  font-size: 16px;
  font-weight: 400;
  color: rgb(36, 45, 65);
`;

const StyledTag = styled.span`
  width: auto;
  height: 34px;
  margin-right: 12px;
  padding: 8px 16px;
  border-radius: 17px;
  background-color: rgba(30, 91, 181, 0.05);
  span {
    font-size: 14px;
    font-weight: 600;
    color: #1e5bb5;
    line-height: 1.29;
  }
`;

const CaseStudyItem = (props) => {
  const { blogDefault } = props.data || {};
  const [pageSize, setPageSize] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [loaded, setLoaded] = useState(false);

  const events = [
    {
      link: "https://www.aircraftit.com/webinars/trustflight-electronic-technical-logbook-etl-elb-solution-demo-webinar/?area=mro",
      date: "On-demand webinar",
      title: "Electronic Tech Log webinar",
      type: "TrustFlight overview, product walkthrough, implementation and main takeaways",
    },
  ];

  useEffect(() => {
    if (
      // No idea what this id is. WTF, why do we have to do this?
      props?.pageResources?.staticQueryResults["3940960797"]?.data
        ?.allMarkdownRemark?.edges
    ) {
      setTotal(
        props?.pageResources?.staticQueryResults["3940960797"]?.data
          ?.allMarkdownRemark?.edges?.length
      );
    }
  }, [props]);

  const renderLatestCaseStudies = (edge) => {
    return (
      <TopBlogDiv>
        <Row>
          <Link
            to={edge.node.frontmatter.slug}
            className={pageStyles.fullLink}
          />
          <Col md={6} className={pageStyles.coliPad}>
            <Img
              fluid={
                edge.node.frontmatter.featuredImage
                  ? edge.node.frontmatter.featuredImage.childImageSharp.fluid
                  : blogDefault?.childImageSharp?.fluid
              }
              className={styles.topBlogImg}
            />
          </Col>
          <Col md={6} className={`${styles.topTextCol} ${pageStyles.coliPad}`}>
            <DateWrapper>{edge.node.frontmatter.date}</DateWrapper>
            <TopTextContent>
              <StyledMainBlogPostHeader>
                {edge.node.frontmatter.title}
              </StyledMainBlogPostHeader>
              <StyledMainBlogText>
                {edge?.node?.frontmatter?.sample}
              </StyledMainBlogText>
            </TopTextContent>
            <GoArrow>
              <GoText>Read more</GoText>
              <img src={goArrow} alt="goArrow" />
            </GoArrow>
          </Col>
        </Row>
      </TopBlogDiv>
    );
  };
  return (
    <Layout>
      <Seo title="Case Studies" />
      <PageHeader mediumHeader />
      <StaticQuery
        query={CASE_STUDIES_POST_QUERY}
        render={({ allMarkdownRemark }) => {
          setLoaded(true);
          let caseStudies = allMarkdownRemark.edges;
          const latestCaseStudies = caseStudies[0];
          caseStudies = caseStudies.slice(1);
          const paginatedCaseStudies = caseStudies.slice(
            (currentPage - 1) * pageSize,
            currentPage * pageSize
          );
          return (
            <Container key={"caseStudies"}>
              <Row className="justify-content-md-center">
                <Col lg={12}>{renderLatestCaseStudies(latestCaseStudies)}</Col>
              </Row>
              <Row>
                <Col lg={9} md={12}>
                  <CaseStudiesHeading name="case-studies-heading">
                    Case Studies
                  </CaseStudiesHeading>
                  <Border />
                  {paginatedCaseStudies.map((edge) => {
                    return (
                      <StandardBlogPostContent>
                        <Link
                          to={edge.node.frontmatter.slug}
                          className={pageStyles.fullLink}
                        />
                        <PostWrapper>
                          <Row>
                            <Col
                              md={12}
                              lg={12}
                              xl={12}
                              className={styles.marginBottom28}
                            >
                              <DateWrapper>
                                {edge.node.frontmatter.date}
                              </DateWrapper>
                              <LowerBlogText>
                                {edge.node.frontmatter.title}
                              </LowerBlogText>
                              {edge.node.frontmatter.tags.map((tag) => (
                                <StyledTag key={tag}>
                                  <span>{tag}</span>
                                </StyledTag>
                              ))}
                            </Col>
                          </Row>
                          <Row>
                            <Col md={5} sm={12} className={styles.showMobile}>
                              <Img
                                fluid={
                                  edge.node.frontmatter.featuredImage
                                    ? edge.node.frontmatter.featuredImage
                                        .childImageSharp.fluid
                                    : blogDefault?.childImageSharp?.fluid
                                }
                                className={styles.standardBlogImage}
                              />
                            </Col>
                            <Col md={7} sm={12}>
                              <BlogText>
                                {edge.node.frontmatter.sample}
                              </BlogText>
                              <GoArrow>
                                <GoText>Read more</GoText>
                                <img src={goArrow} alt="goArrow" />
                              </GoArrow>
                            </Col>
                            <Col md={5} sm={12} className={styles.noShowMobile}>
                              <Img
                                fluid={
                                  edge.node.frontmatter.featuredImage
                                    ? edge.node.frontmatter.featuredImage
                                        .childImageSharp.fluid
                                    : blogDefault?.childImageSharp?.fluid
                                }
                                className={styles.standardBlogImage}
                              />
                            </Col>
                          </Row>
                        </PostWrapper>
                      </StandardBlogPostContent>
                    );
                  })}
                </Col>
                <Col md={3}>
                  <CaseStudiesHeading>Events</CaseStudiesHeading>
                  <Border />
                  {events.map((event) => {
                    return (
                      <EventCard>
                        <a
                          href={event.link}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <EventDate>{event.date}</EventDate>
                          <EventTitle>{event.title}</EventTitle>
                          <EventType>{event.type}</EventType>
                          <EventButton>
                            <span>Find out more</span>
                            <GoArrow noMargin>
                              <img src={goArrowWhite} alt="goArrow" />
                            </GoArrow>
                          </EventButton>
                        </a>
                      </EventCard>
                    );
                  })}
                </Col>
              </Row>
              {total > 10 && loaded ? (
                <LocalPagination
                  total={total}
                  setPageSize={setPageSize}
                  setCurrentPage={setCurrentPage}
                  passedCurrentPage={currentPage}
                  onChange={() => {
                    scroller.scrollTo("case-studies-heading", {
                      duration: 500,
                      smooth: true,
                      offset: -100,
                    });
                  }}
                  width="75%"
                />
              ) : null}
            </Container>
          );
        }}
      />
      <GradientSection noMargin={total > 10} />
      <Container>
        <Started />
      </Container>
    </Layout>
  );
};
export default CaseStudyItem;
